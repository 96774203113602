const mapOptions: L.MapOptions = {
  center: [
    46.2050295,
    6.1440885
  ],
  zoom: 13,
  minZoom: 8,
  zoomControl: false,
  maxBounds: [
    [
      45.3888905014,
      5.3333560101
    ],
    [
      48.0615835854,
      11.2427689718
    ]
  ]
}

const voyager = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png'

const voyagerOptions: L.TileLayerOptions = {
  id: 'voyager',
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> &copy; <a href="https://carto.com/attribution/" target="_blank">CARTO</a>',
  maxZoom: 19
}

const satellite = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'

const satelliteOptions: L.TileLayerOptions = {
  id: 'satellite',
  attribution: '&copy; <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer" target="_blank">ArcGIS</a>',
  maxZoom: 19
}

export {
  mapOptions,
  voyager,
  voyagerOptions,
  satellite,
  satelliteOptions
}
