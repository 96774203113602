
import { defineComponent, toRef } from 'vue'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItem, IonItemGroup, IonItemDivider, IonLabel } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'
import { state } from '@/store'

export default defineComponent({
  name: 'About',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonItemGroup,
    IonItemDivider,
    IonLabel
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  setup () {
    return {
      closeOutline,
      title: toRef(state, 'title'),
      email: toRef(state, 'email'),
      websiteUrl: toRef(state, 'websiteUrl'),
      locale: toRef(state, 'locale'),
      messages: toRef(state, 'messages')
    }
  }
})
