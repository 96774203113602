
import { defineComponent, toRef } from 'vue'
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, popoverController } from '@ionic/vue'
import { ellipsisVertical } from 'ionicons/icons'
import { state } from '@/store'
import Menu from '@/components/Menu.vue'

export default defineComponent({
  name: 'AppBar',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon
  },
  setup () {
    const openMenu = async (event: Event) => {
      state.map?.closePopup()

      const popover = await popoverController.create({
        component: Menu,
        componentProps: {
          closeMenu: () => popover.dismiss()
        },
        event,
        translucent: true
      })

      return popover.present()
    }

    return {
      title: toRef(state, 'title'),
      ellipsisVertical,
      openMenu
    }
  }
})
