import firebase from '@firebase/app'
import '@firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyAKLlb71avJsx2I_TWfohLfW8XupMA3G_M',
  authDomain: 'bibulus-50edd.firebaseapp.com',
  databaseURL: 'https://bibulus-50edd.firebaseio.com',
  projectId: 'bibulus-50edd',
  storageBucket: 'bibulus-50edd.appspot.com',
  messagingSenderId: '976005772384'
})

const api = firebase.firestore()
api.enablePersistence()

export default api
