import { alertController } from '@ionic/vue'
import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

const getLanguage = (languages: Array<any>): string => {
  const navigatorLanguage: string = navigator.language.substring(0, 2)
  const language: string = languages.map(language => language.value).includes(navigatorLanguage) ? navigatorLanguage : 'en'

  Storage.set({
    key: 'bibLanguage',
    value: language
  })

  return language
}

const showAlert = (header: string, message: string, buttons: Array<any> = ['OK'], backdropDismiss = true): Promise<HTMLIonAlertElement> => alertController.create({
  header,
  message,
  buttons,
  backdropDismiss
})

export {
  getLanguage,
  showAlert
}
