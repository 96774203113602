<template>
  <ion-page>
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <ion-text
            v-if="offline"
            color="dark"
            class="ion-text-center"
          >
            <p>
              {{ errorMessage }}
            </p>
          </ion-text>
          <ion-spinner
            v-else
            name="crescent"
            color="dark"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { IonPage, IonGrid, IonRow, IonCol, IonText, IonSpinner } from '@ionic/vue'

export default defineComponent({
  name: 'AppLoading',
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonSpinner
  },
  props: {
    offline: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const errorMessage = ref('')

    switch (navigator.language.substring(0, 2)) {
      case 'de':
        errorMessage.value = 'Etwas ist schief gelaufen. Bitte überprüfen Sie Ihre Verbindung und starten Sie die App neu oder aktualisieren Sie sie.'
        break

      case 'fr':
        errorMessage.value = 'Un problème est survenu. Veuillez vérifier votre connexion et redémarrer ou mettre à jour l\'app.'
        break

      case 'it':
        errorMessage.value = 'Si è verificato un problema. Controlla la tua connessione e riavvia o aggiorna l\'app.'
        break

      default:
        errorMessage.value = 'Something went wrong. Please check your connection and restart or update the app.'
        break
    }

    return {
      errorMessage
    }
  }
})
</script>

<style scoped>
.ion-page {
  background-color: #fff;
}

ion-row {
  height: 100%;
}

ion-spinner {
  transform: scale(2);
}
</style>
