import { reactive } from 'vue'
import { State } from '@/lib/types'

const state: State = reactive({
  title: 'Bibulus',
  email: 'team@bibulus.ch',
  websiteUrl: process.env.VUE_APP_WEBSITE,
  languages: [],
  locale: '',
  messages: {}
})

const setLanguages = (languages: Array<any>): void => {
  state.languages = languages
}

const setLocale = (locale: string): void => {
  state.locale = locale
}

const setMessages = (messages: any): void => {
  state.messages = messages
}

const setMap = (map: L.Map): void => {
  state.map = map
}

const setHint = (hint: any): void => {
  state.hint = hint
}

export {
  state,
  setLanguages,
  setLocale,
  setMessages,
  setMap,
  setHint
}
