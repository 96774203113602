
import { defineComponent, toRef } from 'vue'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItemGroup, IonItemDivider, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'
import { state, setLocale } from '@/store'
import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

export default defineComponent({
  name: 'Settings',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItemGroup,
    IonItemDivider,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  setup () {
    const changeLocale = (event: CustomEvent): void => {
      setLocale(event.detail.value)

      Storage.set({
        key: 'bibLanguage',
        value: event.detail.value
      })
    }

    return {
      closeOutline,
      languages: toRef(state, 'languages'),
      locale: toRef(state, 'locale'),
      messages: toRef(state, 'messages'),
      changeLocale
    }
  }
})
