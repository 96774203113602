<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>
          <span class="ion-text-wrap">
            {{ messages.about.title[locale] }}
          </span>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              :icon="closeOutline"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- content -->
    <ion-content class="ion-padding-horizontal">
      <ion-list>
        <ion-item-group>
          <!-- version -->
          <ion-item-divider mode="md">
            <ion-label>
              {{ messages.about.version[locale] }}
            </ion-label>
          </ion-item-divider>

          <!-- app version -->
          <ion-item lines="none">
            <ion-label>
              {{ messages.about.appVersion[locale] }}
            </ion-label>
          </ion-item>

          <!-- contact -->
          <ion-item-divider mode="md">
            <ion-label>
              {{ messages.about.contact[locale] }}
            </ion-label>
          </ion-item-divider>

          <!-- website -->
          <ion-item lines="none">
            <ion-button
              fill="clear"
              :href="`${websiteUrl}${locale}`"
              target="_blank"
            >
              {{ messages.about.website[locale] }}
            </ion-button>
          </ion-item>

          <!-- contact us -->
          <ion-item lines="none">
            <ion-button
              fill="clear"
              :href="`mailto:${email}`"
            >
              {{ messages.about.contactUs[locale] }}
            </ion-button>
          </ion-item>

          <!-- legal -->
          <ion-item-divider mode="md">
            <ion-label>
              {{ messages.about.legal[locale] }}
            </ion-label>
          </ion-item-divider>

          <!-- terms -->
          <ion-item lines="none">
            <ion-button
              fill="clear"
              :href="`${websiteUrl}${locale}/terms`"
              target="_blank"
            >
              {{ messages.about.terms[locale] }}
            </ion-button>
          </ion-item>

          <!-- privacy -->
          <ion-item lines="none">
            <ion-button
              fill="clear"
              :href="`${websiteUrl}${locale}/privacy`"
              target="_blank"
            >
              {{ messages.about.privacy[locale] }}
            </ion-button>
          </ion-item>

          <!-- rights -->
          <ion-item lines="none">
            <ion-label>
              {{ `© ${title} ${new Date().getFullYear()}` }}. {{ messages.about.rights[locale] }}.
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, toRef } from 'vue'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItem, IonItemGroup, IonItemDivider, IonLabel } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'
import { state } from '@/store'

export default defineComponent({
  name: 'About',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonItemGroup,
    IonItemDivider,
    IonLabel
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  setup () {
    return {
      closeOutline,
      title: toRef(state, 'title'),
      email: toRef(state, 'email'),
      websiteUrl: toRef(state, 'websiteUrl'),
      locale: toRef(state, 'locale'),
      messages: toRef(state, 'messages')
    }
  }
})
</script>
