
import { defineComponent, toRef } from 'vue'
import { IonList, IonItem, IonLabel, IonIcon, modalController } from '@ionic/vue'
import { settingsOutline, informationCircleOutline } from 'ionicons/icons'
import { state } from '@/store'
import Settings from '@/components/Settings.vue'
import About from '@/components/About.vue'

export default defineComponent({
  name: 'Menu',
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonIcon
  },
  props: {
    closeMenu: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const openModal = async (componentName: string): Promise<void> => {
      props.closeMenu()
      const component = componentName === 'Settings' ? Settings : About

      const modal = await modalController.create({
        component,
        componentProps: {
          closeModal: () => modal.dismiss()
        }
      })

      modal.present()
    }

    return {
      locale: toRef(state, 'locale'),
      messages: toRef(state, 'messages'),
      settingsOutline,
      informationCircleOutline,
      openModal
    }
  }
})
