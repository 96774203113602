<template>
  <ion-page>
    <AppBar />
    <ion-content>
      <!-- map -->
      <div id="map" />

      <!-- layers -->
      <ion-fab
        slot="fixed"
        vertical="bottom"
        horizontal="start"
      >
        <ion-fab-button
          color="light"
          @click="changeTileLayer"
        >
          <ion-icon :icon="layers" />
        </ion-fab-button>
      </ion-fab>

      <!-- geolocation -->
      <ion-fab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
      >
        <ion-fab-button
          color="light"
          :class="{
            'rotate': showAnimation
          }"
          :disabled="showAnimation"
          @click="getCurrentPosition"
        >
          <ion-icon :icon="locate" />
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { IonPage, IonContent, IonFab, IonFabButton, IonIcon, onIonViewWillEnter, onIonViewDidEnter, isPlatform, toastController } from '@ionic/vue'
import { locate, layers } from 'ionicons/icons'
import AppBar from '@/components/AppBar.vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import L from 'leaflet'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import { state, setMap } from '@/store'
import { mapOptions, voyager, voyagerOptions, satellite, satelliteOptions } from '@/lib/constants'
import { showAlert } from '@/lib/utils'
import api from '@/lib/api'
import { Plugins } from '@capacitor/core'
const { Geolocation } = Plugins

export default defineComponent({
  name: 'Home',
  components: {
    IonPage,
    AppBar,
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon
  },
  setup () {
    const showAnimation = ref(false)
    const fountains: Array<any> = []
    let map: L.Map
    let tileLayer: L.TileLayer
    let fountainsFetched: Promise<void>
    let currentPosition: string
    let currentPositionMarker: L.CircleMarker
    let isFirstCurrentPosition: boolean
    let currentPositionTimeout: any
    const currentPositionOptions = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 30000
    }

    const fetchFountains = async (): Promise<void> => {
      try {
        const data = await api.collection('fountains').get()

        data.docs.forEach(doc => {
          fountains.push({
            id: doc.id,
            ...doc.data()
          })
        })
      } catch (error) {
        const toast = await toastController.create({
          cssClass: 'custom-error-toast',
          message: state.messages.general.somethingWentWrong[state.locale],
          color: 'danger',
          duration: 3000
        })

        toast.present()
      }
    }

    const displayFountains = async (): Promise<void> => {
      await fountainsFetched

      const markers = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        disableClusteringAtZoom: 16,
        maxClusterRadius: 75
      })

      const mapProduct = isPlatform('ios') ? 'https://maps.apple.com/?daddr=' : 'https://maps.google.com?daddr='

      fountains.forEach(({ coordinates, drinkable }) => {
        const latitude = coordinates.latitude
        const longitude = coordinates.longitude

        const marker = L.marker(
          [latitude, longitude],
          {
            alt: drinkable ? state.messages.home.drinkableFountain[state.locale] : state.messages.home.nonDrinkableFountain[state.locale],
            icon: L.icon({
              iconUrl: drinkable ? '/assets/drinkable-fountain.svg' : '/assets/non-drinkable-fountain.svg',
              iconSize: [32, 32]
            })
          }
        )

        marker.on('click', () => {
          L.popup()
            .setLatLng([latitude, longitude])
            .setContent(`
                <span style="font-weight: bold;">${drinkable ? state.messages.home.drinkableFountain[state.locale] : state.messages.home.nonDrinkableFountain[state.locale]}</span><br>
                <a target="_blank" href="${mapProduct}${latitude},${longitude}">${state.messages.home.directions[state.locale]}<a/>
              `)
            .openOn(map)
        })

        markers.addLayer(marker)
      })

      map.addLayer(markers)
    }

    const changeTileLayer = (): void => {
      map.removeLayer(tileLayer)

      if (tileLayer.options.id === 'voyager') {
        tileLayer = L.tileLayer(satellite, satelliteOptions).addTo(map)
      } else {
        tileLayer = L.tileLayer(voyager, voyagerOptions).addTo(map)
      }
    }

    const getCurrentPosition = (): void => {
      showAnimation.value = true
      isFirstCurrentPosition = true
      Geolocation.clearWatch({ id: currentPosition })
      if (currentPositionMarker) map.removeLayer(currentPositionMarker)
      if (currentPositionTimeout) clearTimeout(currentPositionTimeout)
      currentPositionTimeout = null

      currentPosition = Geolocation.watchPosition(currentPositionOptions, async (position, error) => {
        if (error) {
          let message

          switch (error.code) {
            case 1:
              message = state.messages.home.geolocationErrorMessagePermission[state.locale]
              break
            case 3:
              message = state.messages.home.geolocationErrorMessageTimeout[state.locale]
              break
            default:
              message = state.messages.home.geolocationErrorMessageUnavailable[state.locale]
              break
          }

          Geolocation.clearWatch({ id: currentPosition })
          if (currentPositionMarker) map.removeLayer(currentPositionMarker)
          if (currentPositionTimeout) clearTimeout(currentPositionTimeout)

          const alert = await showAlert(
            state.messages.home.geolocation[state.locale],
            message
          )

          alert.present()
          showAnimation.value = false
        } else {
          if (currentPositionMarker) map.removeLayer(currentPositionMarker)

          const latitude = position.coords.latitude
          const longitude = position.coords.longitude

          currentPositionMarker = L.circleMarker(
            [latitude, longitude],
            {
              radius: 7,
              color: '#1976d2',
              opacity: 0.3,
              weight: 15,
              fillColor: '#1976d2',
              fillOpacity: 1
            }
          ).addTo(map)

          if (isFirstCurrentPosition) {
            map.setView(
              [latitude, longitude],
              17
            )

            isFirstCurrentPosition = false
          }

          if (!currentPositionTimeout) {
            currentPositionTimeout = setTimeout(async () => {
              Geolocation.clearWatch({ id: currentPosition })
              if (currentPositionMarker) map.removeLayer(currentPositionMarker)

              const alert = await showAlert(
                state.messages.home.geolocation[state.locale],
                state.messages.home.geolocationStopMessage[state.locale]
              )

              alert.present()
            }, 1000 * 60 * 10)
          }

          showAnimation.value = false
        }
      })
    }

    onIonViewWillEnter(() => {
      fountainsFetched = fetchFountains()
    })

    onIonViewDidEnter(() => {
      map = L.map('map', mapOptions)
      setMap(map)
      tileLayer = L.tileLayer(voyager, voyagerOptions).addTo(map)
      setTimeout(() => map.invalidateSize(), 300)
      displayFountains()

      if (state.hint?.active) {
        setTimeout(async () => {
          const toast = await toastController.create({
            cssClass: isPlatform('ios') ? 'custom-toast-ios' : 'custom-toast',
            message: state.hint.message[state.locale],
            color: 'secondary',
            position: 'top',
            buttons: [{
              text: state.messages.general.close[state.locale],
              role: 'cancel'
            }]
          })

          await toast.present()
        }, 1000)
      }
    })

    return {
      locate,
      layers,
      showAnimation,
      changeTileLayer,
      getCurrentPosition
    }
  }
})
</script>
