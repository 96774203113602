<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>
          <span class="ion-text-wrap">
            {{ messages.settings.title[locale] }}
          </span>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal">
            <ion-icon
              slot="icon-only"
              :icon="closeOutline"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- content -->
    <ion-content class="ion-padding-horizontal">
      <ion-list>
        <ion-item-group>
          <!-- general -->
          <ion-item-divider mode="md">
            <ion-label>
              {{ messages.settings.general[locale] }}
            </ion-label>
          </ion-item-divider>

          <!-- language -->
          <ion-item lines="full">
            <ion-label>
              {{ messages.settings.language[locale] }}
            </ion-label>
            <ion-select
              :cancel-text="messages.general.cancel[locale]"
              :value="locale"
              @ion-change="changeLocale($event)"
            >
              <ion-select-option
                v-for="(item, index) in languages"
                :key="index"
                :value="item.code"
              >
                {{ item.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, toRef } from 'vue'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonList, IonItemGroup, IonItemDivider, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue'
import { closeOutline } from 'ionicons/icons'
import { state, setLocale } from '@/store'
import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

export default defineComponent({
  name: 'Settings',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonItemGroup,
    IonItemDivider,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  setup () {
    const changeLocale = (event: CustomEvent): void => {
      setLocale(event.detail.value)

      Storage.set({
        key: 'bibLanguage',
        value: event.detail.value
      })
    }

    return {
      closeOutline,
      languages: toRef(state, 'languages'),
      locale: toRef(state, 'locale'),
      messages: toRef(state, 'messages'),
      changeLocale
    }
  }
})
</script>
