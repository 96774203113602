
import { defineComponent, ref } from 'vue'
import { state, setLanguages, setLocale, setMessages, setHint } from '@/store'
import { getLanguage, showAlert } from '@/lib/utils'
import { IonApp, IonRouterOutlet, alertController } from '@ionic/vue'
import AppLoading from '@/components/AppLoading.vue'
import api from '@/lib/api'
import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

export default defineComponent({
  name: 'App',
  components: {
    AppLoading,
    IonApp,
    IonRouterOutlet
  },
  setup () {
    const isLoading = ref(true)
    const isOffline = ref(false)
    const languages: Array<any> = []
    let registration: any = null
    let refreshing = false

    const init = async (): Promise<void> => {
      try {
        const response = await api.collection('languages').get()

        response.docs.forEach(doc => {
          languages.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setLanguages(languages)

        const { value } = await Storage.get({ key: 'bibLanguage' })
        setLocale(value || getLanguage(languages))

        const locales = await api.collection('locales').doc('app').get()
        setMessages(locales.data())

        const hint = await api.collection('notifications').doc('hint').get()
        setHint(hint.data())

        isLoading.value = false
      } catch (error) {
        isOffline.value = true
      }
    }

    init()

    window.onpopstate = () => {
      alertController.dismiss()
    }

    document.addEventListener('swUpdated', async (event: any) => {
      registration = event.detail

      const alert = await showAlert(
        state.title,
        state.messages.general.refreshContentMessage[state.locale],
        [
          {
            text: state.messages.general.refreshContentButtonText[state.locale],
            handler: () => {
              if (!registration || !registration.waiting) return
              registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            }
          }
        ],
        false
      )

      alert.present()
    }, { once: true })

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing || !registration) return
        refreshing = true
        location.reload()
      })
    }

    return {
      isLoading,
      isOffline
    }
  }
})
